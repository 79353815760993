import { FiltersModel } from "@/core/models/filters";

export enum ClientFilterItem {
  SUBSCRIPTION_PLAN = "subscription_plan",
}

export class ClientFiltersModel extends FiltersModel {
  public [ClientFilterItem.SUBSCRIPTION_PLAN]: string;

  constructor(query: any) {
    super();
    (this[ClientFilterItem.SUBSCRIPTION_PLAN] = query[ClientFilterItem.SUBSCRIPTION_PLAN]),
    this.initAttrsFromQuery(query);
  }
}